#message {
    background-color: rgb(112, 52, 224);
    z-index: 4;
    height: 20px;
    width: auto;
    padding: 2px;
    position: absolute;
    bottom: 50px;
    right: 50px;
}
.pageDiv {
    width: 100%;
    background-color: #f1f1f1;
}

.pageDiv > div {
    display: inline-block;
}

.storeInfoDiv {
    width: 75%;
    min-height: 600px;
    background-color: #f8f8f8;
    border-right: 0.5px solid #aaa;
}

.cartDiv {
    width: 25%;
    height: auto;
    min-height: 300px;
    vertical-align: top;
    background-color: #eee;
}

@media only screen and (max-width: 1280px) {
    /* 13.3 inch mac icin 1280*/
    .cartDiv {
        width: 30%;
    }
    .storeInfoDiv {
        width: 70%;
    }
}

@media only screen and (max-width: 1100px) {
    .cartDiv {
        width: 34%;
    }
    .storeInfoDiv {
        width: 66%;
    }
}

@media only screen and (max-width: 900px) {
    .cartDiv,
    .storeInfoDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .cartDiv,
    .storeInfoDiv {
        width: 100%;
    }
}
