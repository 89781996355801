.input-group-search {
    background-color: #fff;
    color: #aaa;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    line-height: 34px;
    height: 38px;
    font-size: 20px;
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.searchBar {
    border-left: 0px;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none;
    border-color: #ccc;
}
