.storeImages {
    width: 100%;
    height: 125px;
    display: table;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 0px;
}
.backgroundImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logoDiv {
    display: table-cell;

    vertical-align: middle;
    border-radius: 10px;
}

.logoImg {
    max-height: 90px;
    box-sizing: border-box;
    height: auto;
    margin: auto;
    display: flex;
    object-fit: cover;
    border-radius: 5px;
}

@media only screen and (max-width: 1100px) {
    .logoImg {
        max-height: 85px;
    }
}

@media only screen and (max-width: 700px) {
    .logoImg {
        max-height: 80px;
    }
}
