.storeInfoDiv .storeImages {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  top: 0px;
  height: 240px;
}

.storeInfoDiv .logoImg {
  max-height: 102px;
  margin-left: 5%;
  margin-bottom: -10%;

  box-shadow: 0 0 10px 5px #ccc;
}

.storeInfoDiv .titleContainer {
  padding: 15px 25px;
}

.storeInfoDiv .titleDiv {
  display: table;
  width: 100%;
}

.storeInfoDiv .titleDiv > * {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.storeInfoDiv .titleDiv i {
  font-size: 22px;
  cursor: pointer;
}

.inputTitle {
  border: 1px dashed #bbb;
  color: green;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
}
.inputTitle:focus {
  outline: none !important;
  border: 1px dashed red;
}

@media only screen and (max-width: 1100px) {
  .storeInfoDiv .storeImages {
    height: 160px;
  }
  .storeInfoDiv .logoImg {
    max-height: 85px;

    margin-bottom: -5%;
  }
}
.thumbnail {
  /* min-height: 341px; */
  cursor: pointer;
  padding: 0;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  padding: 15px;
}
.thumbnail.editor .edit {
  border: 2px dashed #aaa;
  height: 100%;
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
  margin: auto;
}
.thumbnail.editor i {
  font-size: 60px;
  margin-bottom: 7px;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .storeInfoDiv {
    margin-bottom: 30px;
  }
  .storeInfoDiv .storeImages {
    height: 120px;
  }
  .storeInfoDiv .logoImg {
    max-height: 70px;
  }

  .storeInfoDiv .titleContainer {
    padding: 15px 5%;
  }
}
