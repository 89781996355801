/* table.varolw {
    display: block;
    width: 100%;
}
table .varol2 {
    display: block;
}

table .varol2 th .divTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14%;
} */

.prodTableContainer {
  max-width: 100%;
  display: block;
}

.prodTableHead {
  text-align: center;
}

label {
  margin-bottom: 0px;
}

.storeInfoDiv .categoriesUl .active {
  border-bottom: none;
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .prodTableContainer {
    overflow-x: scroll;
  }
  .prodTableHead {
    font-size: 14px;
  }
}
