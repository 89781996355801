.addressContainer {
  /* border: 1px solid #aaa; */
  border-radius: 5px;
  background-color: #eee;
  padding: 10px;
  display: inline-block;
  min-height: 80px;
  /* width: 48%;
    margin: 0px 1%; */
  vertical-align: top;
}
.addressContainer .addressInfo {
  background: #f8f8f8;
  position: relative;
  cursor: pointer;
  min-height: 150px;
  padding: 10px;
  border-radius: 5px;
}
.addressContainer .adressText label {
  display: block;
  margin-bottom: 4px;
}
