.drawerButtons .nav-link {
    color: #666;
    border-bottom: 1px solid #aaa;
    font-weight: 500;
}

.drawerButtons {
    overflow: auto;
}

.bg-gray {
    background-color: #b0b0b0;
    margin-bottom: 0px;
}

.drawerButtons .nav-link i:first-child {
    margin-right: 8px;
}
.drawerButtons .nav-link.active {
    color: #ff6600;
}

.bg-tele .fa-times {
    position: relative;
    float: right;
    margin: 0px;
    padding: 0px;
    right: 0px;
    top: 0px;
    font-size: 18px;
    cursor: pointer;
}

.drawerDiv .logo {
    display: flex;
    padding: 0px;
    margin: auto;
}

.drawerDiv .logo img {
    margin-left: 0px;
    height: 32px;
    width: 190px;
    object-fit: scale-down;
}

.drawerButtons > * {
    cursor: pointer;
}

.drawerButtons > .bg-gray {
    cursor: default;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 75%;
    max-width: 300px;
    background-color: #f7f7f7;
}
