.imgDrop .DropImg {
  /* object-fit: cover; */
  /* position: absolute; */
  /* left: 5%; */
  /* top: 5%; */
  width: 100%;
  height: 100%;
  /* display: table-cell; */
}

.imgDrop .dropDiv {
  z-index: 3;
  /* display: table; */
  /* vertical-align: middle; */
  text-align: center;
  position: absolute;
  /* width: 100%;
  height: 100%; */
}
