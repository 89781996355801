.inputDescriptionDiv textarea {
  resize: none;
  width: 80%;
  float: left;
  display: block;
  height: 70px;
}
.inputName {
  font-size: 16px;
  width: 80%;
  float: left;
  display: block;
}
.inputPrice {
  font-size: 15px;
  width: 19%;
  float: right;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  text-align: right;
}
.inputInfoDiv,
.inputDescriptionDiv {
  display: block;
  float: right;
  width: 80%;
  margin-bottom: 6px;
  max-height: 48px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.productDiv {
  width: 48%;
  margin: 10px 1%;
  padding: 10px;
  display: inline-table;
  cursor: pointer;
  border: none;
  background-color: #fefefe;
  height: 120px;
  /* border: 0.5px solid transparent; */
}

.quantity {
  color: #fff;
}

.itemImgDiv {
  height: 80px;

  width: 14%;
  display: block;
  float: left;
}

.itemImgDiv img {
  max-width: 60px;
}

.productDiv:hover {
  /* outline: 0.5px solid #ccc; */
  /* border: 0.5px solid #ccc; */ /*mac te calisiyor*/
  /* border-color: #ccc; */
  box-shadow: 0 0 1.5px 0 #555;
  border-radius: 4px;
}

.productDiv .itemImage {
  float: left;
  width: 100%;
}

.itemInfoDiv,
.descriptionDiv {
  display: block;
  float: right;
  width: 84%;
  margin-bottom: 2px;
  max-height: 60px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.descriptionDiv {
  max-height: 50px;
  font-size: 12px;
  margin-bottom: 0px;
  /* white-space: nowrap;    
     img leri bozuyor, ... icin gerekli */
}

.quantityDiv {
  font-size: 14px;
}

.itemName {
  font-size: 16px;
  width: 50%;
  float: left;
  display: block;
}

.itemPrice {
  font-size: 15px;
  float: right;
  width: 20%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}

@media only screen and (max-width: 1100px) {
  .productDiv {
    max-height: 114px;
  }
  .itemImgDiv {
    width: 28%;

    height: 94px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 68%;
  }

  .itemName {
    max-width: 38%;
  }
}

@media only screen and (max-width: 750px) {
  .productDiv {
    width: 100%;
    margin: 10px 0px;
  }

  .itemImgDiv {
    width: 11%;

    height: 70px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .itemImgDiv {
    width: 17%;

    height: 70px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 80%;
  }
}
