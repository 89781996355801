@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pusher {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signupDiv h6 {
  font-size: 15px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #bbb;
}

.logo {
    padding: 2px 0px;
    font-weight: 500;
    color: #444;
}

.bg-tava {
    background-color: #3e5062;
}

.logo span {
    font-size: 26px;
}

.navbar {
    border-bottom: 0.4px solid #aaa;
}

.dropdown-menu {
    background: #f2f2f2;
}
.dropdown-item {
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: middle;
}

.dropdown-item:hover {
    background: #ccc;
}
.dropdown-item img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}
.nav-link img {
    width: 30px;
    height: 20px;
    margin-right: 5px;
}

.languages {
    border: 0.5px solid #444;
    border-radius: 10px;
}

/* ul li {
    display: inline;
    margin-right: 10px;
} */

.logo img {
    margin-left: 24px;
}
@media only screen and (max-width: 700px) {
    .languages,
    .cartIconDiv {
        max-width: 20%;
        padding-left: 10px;
    }

    .logo img {
        margin-left: 0px;
    }
    .bg-tava .navbar-toggler-icon i {
        color: #fff;
        font-size: 30px;
        margin-top: -1px;
    }
    .bg-tava .navbar-toggler:hover {
        outline: none !important;
        box-shadow: none;
    }
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #ccc;
  cursor: pointer;
}

.active {
  color: #ff6600;
}

.drawerButtons .nav-link {
    color: #666;
    border-bottom: 1px solid #aaa;
    font-weight: 500;
}

.drawerButtons {
    overflow: auto;
}

.bg-gray {
    background-color: #b0b0b0;
    margin-bottom: 0px;
}

.drawerButtons .nav-link i:first-child {
    margin-right: 8px;
}
.drawerButtons .nav-link.active {
    color: #ff6600;
}

.bg-tele .fa-times {
    position: relative;
    float: right;
    margin: 0px;
    padding: 0px;
    right: 0px;
    top: 0px;
    font-size: 18px;
    cursor: pointer;
}

.drawerDiv .logo {
    display: flex;
    padding: 0px;
    margin: auto;
}

.drawerDiv .logo img {
    margin-left: 0px;
    height: 32px;
    width: 190px;
    object-fit: scale-down;
}

.drawerButtons > * {
    cursor: pointer;
}

.drawerButtons > .bg-gray {
    cursor: default;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 75%;
    max-width: 300px;
    background-color: #f7f7f7;
}

.main-footer {
    width: 100%;
    padding: 15px 0px;
    vertical-align: middle;
    /* margin-top: 20px; */
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 0.4px solid #aaa;
    bottom: 0px;
    z-index: 3;
}

.main-footer a {
    color: #ff6600;
}

html {
    height: 100%;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    position: relative;
    margin: 0;
    min-height: 100%;
    padding-bottom: 55px;
}

.contentt {
    width: 100%;

    height: auto;
    min-height: 200px;
    background-color: #fcfcfc;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.bg-tele .navbar-toggler {
    float: left;
    padding: 0;
    margin-top: 3px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #bbb;
}

.logo {
    padding: 2px 0px;
    font-weight: 500;
    color: #444;
}

.navbar {
    border-bottom: 0.4px solid #aaa;
}

.dropdown-menu {
    background: #f2f2f2;
}
.dropdown-item {
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: middle;
}

.dropdown-item:hover {
    background: #ccc;
}
.dropdown-item img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}
.nav-link img {
    width: 30px;
    height: 20px;
    margin-right: 5px;
}

.languages {
    border: 0.5px solid #444;
    border-radius: 10px;
}

.productsUl {
    list-style: none;
    padding: 0px;
    color: #fff;
    margin: 0px;
    line-height: 38px;
}

/* ul li {
    display: inline;
    margin-right: 10px;
} */

.sortBySelect {
    border: 0.2px solid #aaa;
    padding: 2px 30px 2px 10px;
}
@media only screen and (max-width: 1100px) {
    h4 {
        font-size: 21px;
    }
}
@media only screen and (max-width: 600px) {
    .nav-link img {
        width: 40px;
        height: 27px;
        margin-right: 5px;
    }
}

.signupDiv {
    margin: 20px auto;
    background-color: #f4f4f4;
    display: block;
    padding: 25px 22px;
    color: #333;
    border-radius: 10px;
    width: 30%;
}

.imgmango {
    display: flex;
    width: 220px;
    margin: 0px auto 20px;
}

.signupDiv form {
    margin-top: 20px;
}

form .btn {
    display: block;
    margin: 30px auto 10px;
    width: 50%;
}

form label {
    font-weight: 600;
}

@media only screen and (max-width: 1100px) {
    .signupDiv {
        width: 50%;
    }
}

@media only screen and (max-width: 700px) {
    .signupDiv {
        width: 90%;
    }
}


.storeDiv {
    display: inline-block;
    padding: 5px;
    margin-bottom: 10px;

    width: 25%;
}

.storeDiv .content {
    min-height: 200px;
    border-radius: 10px;
    border: 1px solid #bbb;
}

.storeItemInfoDiv {
    padding: 10px 15px;
    cursor: pointer;

    font-size: 13px;
}

.storeItemInfoDiv > div {
    margin-bottom: 5px;
}

.storeName {
    font-weight: bold;
    font-size: 15px;
}

@media only screen and (max-width: 1100px) {
    .storeDiv {
        width: 33.33%;
    }
}

@media only screen and (max-width: 750px) {
    .storeDiv {
        min-width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .storeDiv {
        min-width: 100%;
    }
}

.ratingDiv {
    display: table;
    margin-top: 10px;
}

.rating {
    display: inline-block;
    /* color: #888888; */
    height: 25px;
    width: auto;
    margin: 0;
    font-size: 25px;
    margin-right: 10px;

    position: relative;
    padding: 0;
}

.rating-upper {
    color: orange;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;

    overflow: hidden;
}

.rating-lower {
    padding: 0;
    display: flex;
    z-index: 0;
}

.starText {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
}

@media only screen and (max-width: 1100px) {
    .ratingDiv {
        display: inline-block;
    }
}

@media only screen and (max-width: 700px) {
    .ratingDiv {
        display: table;
    }
}

.storeImages {
    width: 100%;
    height: 125px;
    display: table;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 0px;
}
.backgroundImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logoDiv {
    display: table-cell;

    vertical-align: middle;
    border-radius: 10px;
}

.logoImg {
    max-height: 90px;
    box-sizing: border-box;
    height: auto;
    margin: auto;
    display: flex;
    object-fit: cover;
    border-radius: 5px;
}

@media only screen and (max-width: 1100px) {
    .logoImg {
        max-height: 85px;
    }
}

@media only screen and (max-width: 700px) {
    .logoImg {
        max-height: 80px;
    }
}

.imgDrop {
    width: 100%;
    height: 100%;
    /* background: lightgreen; */
    opacity: 1;
    position: relative;
    padding: 0.5%;
}

.imgDrop:hover {
    opacity: 0.8;
}
.imgDrop .borderDash {
    width: 100%;
    height: 100%;
    border: 1px dashed red;
    display: table;
}
.allDropzones p {
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}

.allDropzones {
    position: relative;
    width: 100%;
    height: 300px;
    display: block;
    cursor: pointer;
}

.allDropzones .logoDrop {
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50px;
    bottom: 20px;
    border: 1px dashed red;
    display: table;
}

.allDropzones .logoDrop .logoDropImg {
    object-fit: cover;
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    display: table-cell;
}

.allDropzones .logoDrop .dropDiv {
    z-index: 3;
    display: table;
    vertical-align: middle;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.storeContainer {
    padding: 10px 5%;
    margin-top: 5px;
    min-height: 200px;
    height: auto;
    width: 100%;
}

.help-message {
    text-align: center;
    font-size: 18px;
    padding: 15px;
    border: 1px solid #333;
    border-radius: 5px;
    width: 100%;
    display: block;
    background: #eaeaea;
}

.imgDrop .DropImg {
  /* object-fit: cover; */
  /* position: absolute; */
  /* left: 5%; */
  /* top: 5%; */
  width: 100%;
  height: 100%;
  /* display: table-cell; */
}

.imgDrop .dropDiv {
  z-index: 3;
  /* display: table; */
  /* vertical-align: middle; */
  text-align: center;
  position: absolute;
  /* width: 100%;
  height: 100%; */
}

.cartIconDiv {
    padding: 2px 7px;
    border: 1px solid #333;
    border-radius: 10px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    background: #28a745;
}

.cartIconDiv i {
    margin-right: 8px;
}

.activeLang {
    background-color: #999;
}

.bg-renk > .nav-link {
    color: #fff;
}

.multiImageContainer {
    /* padding-top: 70px; */
    width: 100%;
}
.multiImageContainer > * {
    /* width: 60%; */
    width: 100%;
    display: block;
    margin: auto;
}
.multiImageContainer .firstTab .topText {
    padding-bottom: 8px;
    display: block;
    height: 30px;
}
.multiImageContainer .secondTab .topText {
    display: block;
    background-color: #999;
}
.multiImageContainer .colorText {
    color: #007bff;
    cursor: pointer;
}
ul {
    list-style: none;
    text-decoration: none;
}
.multiImageContainer .seperator {
    margin: 0px 10px;
}

.multiImageContainer .multiImageDiv {
    border: 1px solid #888;
    border-radius: 2px;
    min-height: 300px;
    position: relative;
}
.multiImageContainer .multiImageDiv > * {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.addImageBtn {
    border: 1px solid #aaa;
    color: #007bff;
}

.allImagesDiv ul {
    margin: 0px;
    padding: 0px;
    display: block;
}

.imageItemDrag {
    display: inline-block;
    border: 1px solid #888;
    /* width: 33.333%; */
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    color: #007bff;
    position: relative;
    cursor: move;
}

.imageItemDrag img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
}

.imageItemDrag .deleteItemDrag {
    width: 28px;
    height: 25px;
    position: absolute;
    right: -3px;
    top: -2px;
    z-index: 11;
    cursor: pointer;

    background: transparent;
    border: none;

    outline: none !important;
    box-shadow: none;
}

.imageItemDrag .deleteItemDrag img {
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.addImageDiv {
    padding: 5px 2%;
}
.multiImageDiv .editImage {
    position: absolute;
    left: 0px;
    top: 5px;
    bottom: 5px;
    width: 50%;
    height: calc(100% - 10px);
}

.multiImageDiv .editImage img {
    width: 96%;
    margin: 0px auto 5px;
    height: calc(100% - 55px);
    object-fit: cover;
    display: block;
}

.clickElement {
    position: absolute;
    z-index: 10;
    left: 1px;
    right: 15px;
    top: 15px;
    bottom: 15px;
    cursor: pointer;
    background: transparent;
    border: none;

    outline: none !important;
    box-shadow: none;
}

.multiImageDiv .editImage .editOptionsDiv {
    display: block;
    width: 100%;
    border-top: 1px solid #888;
    height: 50px;
    padding: 5px 10px;
    font-size: 22px;
    line-height: 40px;
    color: #888;
}

.multiImageDiv .editImage i {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.allImagesDiv ul li:nth-child(1) > * {
    /* display: inline-flex;
    height: 100%; */
}

.allImagesDiv ul li > * {
    width: 100%;
    height: 80px;
    display: block;
    margin-bottom: 0px;
}

.allImagesDiv ul li.addBtnTest:hover {
    color: blue;
    border: 1px dashed #444;
}

.allImagesDiv ul li label i {
    line-height: 45px;
    font-size: 25px;
}

.secondTab .addWebUrl {
    font-weight: 500;
    font-size: 18px;
}
.secondTab .webImageDiv {
    padding: 30px 50px;
    border: 1px solid #888;
    border-radius: 2px;
    min-height: 300px;
}
.webImageDiv .btn {
    width: auto;
}

.secondTab .addWebUrlDiv {
    margin: 10px 0px 80px 45px;
}

.secondTab .btn {
    display: inline-block;
}

.secondTab .webUrls .form-group label {
    width: 30px;
    text-align: center;
    margin: 0px 10px 15px 0px;
}

.secondTab .webUrls .form-group input {
    width: 350px;
}

.multiImageContainer .errorMessage {
    color: red;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
}

@media only screen and (max-width: 991px) {
    .multiImageContainer > * {
        /* width: 80%; */
    }
}

@media only screen and (max-width: 767px) {
    .multiImageContainer .multiImageDiv > * {
        width: 100%;
    }
    .multiImageContainer > * {
        width: 90%;
    }
    .multiImageContainer .firstTab .addImageDiv {
        padding: 30px 10px;
    }
}

.multiImageContainer {
  padding-top: 10px;
  width: 100%;
}
.multiImageContainer > * {
  width: 100%;
  display: block;
  margin: auto;
}
.multiImageContainer .firstTab .topText {
  padding-bottom: 8px;
  display: block;
  height: 30px;
}
.multiImageContainer .secondTab .topText {
  display: block;
  background-color: #999;
}
.multiImageContainer .colorText {
  color: #007bff;
  cursor: pointer;
}
ul {
  list-style: none;
  text-decoration: none;
}
.multiImageContainer .seperator {
  margin: 0px 10px;
}

.multiImageContainer .multiImageDiv {
  border: 1px solid #888;
  border-radius: 2px;
  min-height: 300px;
  position: relative;
}
.multiImageContainer .multiImageDiv > * {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.addImageBtn {
  border: 1px solid #aaa;
  color: #007bff;
}

.allImagesDiv ul {
  margin: 0px;
  padding: 0px;
  display: block;
}

.imageItemDrag {
  display: inline-block;
  border: 1px solid #888;
  /* width: 33.333%; */
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  color: #007bff;
  position: relative;
  cursor: move;
}

.imageItemDrag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.imageItemDrag .deleteItemDrag {
  width: 28px;
  height: 25px;
  position: absolute;
  right: -3px;
  top: -2px;
  z-index: 11;
  cursor: pointer;

  background: transparent;
  border: none;

  outline: none !important;
  box-shadow: none;
}

.imageItemDrag .deleteItemDrag img {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.addImageDiv {
  padding: 5px 2%;
}
.multiImageDiv .editImage {
  position: absolute;
  left: 0px;
  top: 5px;
  bottom: 5px;
  width: 50%;
  height: calc(100% - 10px);
}

.multiImageDiv .editImage img {
  width: 96%;
  margin: 0px auto 5px;
  height: calc(100% - 55px);
  object-fit: cover;
  display: block;
}

.clickElement {
  position: absolute;
  z-index: 10;
  left: 1px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  cursor: pointer;
  background: transparent;
  border: none;

  outline: none !important;
  box-shadow: none;
}

.multiImageDiv .editImage .editOptionsDiv {
  display: block;
  width: 100%;
  border-top: 1px solid #888;
  height: 50px;
  padding: 5px 10px;
  font-size: 22px;
  line-height: 40px;
  color: #888;
}

.multiImageDiv .editImage i {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.allImagesDiv ul li:nth-child(1) > * {
  /* display: inline-flex;
    height: 100%; */
}

.allImagesDiv ul li > * {
  width: 100%;
  height: 80px;
  display: block;
  margin-bottom: 0px;
}

.allImagesDiv ul li.addBtnTest:hover {
  color: blue;
  border: 1px dashed #444;
}

.allImagesDiv ul li label i {
  line-height: 45px;
  font-size: 25px;
}

.secondTab .addWebUrl {
  font-weight: 500;
  font-size: 18px;
}
.secondTab .webImageDiv {
  padding: 30px 50px;
  border: 1px solid #888;
  border-radius: 2px;
  min-height: 300px;
}
.webImageDiv .btn {
  width: auto;
}

.secondTab .addWebUrlDiv {
  margin: 10px 0px 80px 45px;
}

.secondTab .btn {
  display: inline-block;
}

.secondTab .webUrls .form-group label {
  width: 30px;
  text-align: center;
  margin: 0px 10px 15px 0px;
}

.secondTab .webUrls .form-group input {
  width: 350px;
}

.multiImageContainer .errorMessage {
  color: red;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  margin-top: 5px;
}

@media only screen and (max-width: 991px) {
  .multiImageContainer > * {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .multiImageContainer .multiImageDiv > * {
    width: 100%;
  }
  .multiImageContainer > * {
    width: 90%;
  }
  .multiImageContainer .firstTab .addImageDiv {
    padding: 30px 10px;
  }
}
.selectedImg {
  box-sizing: border-box;
  border: 5px solid red;
}

.deleteDraft {
  cursor: pointer;
  color: #df0808;
}
.titleDiv1 {
  display: table;
}

.titleDiv1 input {
  width: calc(100% - 75px);
}

.titleDiv1 button {
  width: 75px;
}

.input-group-search {
    background-color: #fff;
    color: #aaa;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    line-height: 34px;
    height: 38px;
    font-size: 20px;
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.searchBar {
    border-left: 0px;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none;
    border-color: #ccc;
}

.btnFace {
    padding: 10px 0px;
    background-color: #3b5998;
    border: 1px solid #3b5998;
    border-radius: 3px;
    color: white;
    font-weight: 600;
}

.btnGuest {
    padding: 10px 0px;

    border: 1px solid #777;
    border-radius: 3px;
    font-weight: 600;
}

.divider span {
    display: block;
    width: 50px;
    background: #f4f4f4;
    text-align: center;
    color: #999;
    margin: -30px auto 0 auto;
    padding: 0 7px;
}



.storeInfoDiv .pull-right {
    width: 35%;
}
.storeInfoDiv .categoriesUl {
    list-style: none;
    padding: 0px;
    color: #fff;
    margin: 0px;
    line-height: 38px;
}
.storeInfoDiv .categoryLi {
    cursor: pointer;
    color: blue;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
}

.storeInfoDiv .categoriesContainer {
    padding: 0px 3%;
    border-bottom: 0.6px solid #aaa;
    min-height: 60px;
    /* display: table;
     width: 100%; */
}

.storeInfoDiv .categoriesContainer > * {
    margin-bottom: 12px;
}

.storeInfoDiv .categoriesUl .active {
    /* text-decoration: underline; */ /*bunun disinda*/
    border-bottom: 2px solid blue;
}
.storeInfoDiv hr {
    display: none;
}
/* .storeInfoDiv  hr {
     display: block;
     border: none;
     border-bottom: 0.6px solid #aaa;
     margin: 0px -3%;
} */

@media only screen and (max-width: 1100px) {
    .storeInfoDiv .categoriesContainer {
        display: table;

        padding: 0px 5%;
        width: 100%;
    }
    .storeInfoDiv .pull-right {
        width: 100%;
    }

    .storeInfoDiv hr {
        display: block;
        border: none;
        border-bottom: 0.6px solid #aaa;
        margin: 0px -5%;
    }

    .storeInfoDiv .pull-left {
        min-width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .storeInfoDiv .categoriesUl > li {
        padding: 0px 4px;
    }
}

.imgPopup h3 {
    color: green;
}

.imgPopup {
    height: 100%;
    width: 100%;
}

.imgPopup i {
    display: none;
}

.imgPopup img {
    width: 100%;
    height: auto;
}

.modalBody {
    max-height: 485px;
}

@media only screen and (max-width: 700px) {
}

.modal-footer > .changeQuantityDiv {
    /* margin: 5px 10px; */
    /* display: inline-block; */
    padding-top: 3px;
    padding-left: 0px;
}

.changeQuantityDiv > div {
    padding: 0px 12px 0px 6px;
    display: inline-block;
    font-size: 27px;
    font-weight: 700;
    cursor: pointer;
}

.orderTextarea {
    min-height: 80px;
    margin-top: 10px;
}

.plusDiv {
    color: #28a745;
}

button.close {
    font-size: 30px;
}

.addButton {
    padding: 8px 15px;
}

.modal-dialog {
    max-width: 50%;
    height: 500px;
}

.photoGallery {
    margin-bottom: 15px;
}

.modal-body {
    max-height: 400px;
    overflow-y: auto;
}
.modal-content {
    min-height: 100%;
    overflow-y: auto;
}

.addCartButton {
    min-width: 180px;
}

.quantText {
    float: left;
    width: 30px;
    background-color: #226622;
    display: block;
    text-align: center;

    border-radius: 5px;
}

.btnText {
    min-width: 80%;
    text-align: center;
}

.form-group {
    margin-bottom: 0px;
}
.inputBoxes {
    display: table;
    width: 100%;
    margin-bottom: 5px;
}

.inputBoxes > input {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    margin-right: 8px;
    margin-left: 10px;
}

.inputBoxes > label {
    display: table-cell;
    vertical-align: middle;
    margin-left: 8px;
    width: 100%;
}

.inputBoxes > label > .float-right {
    margin-right: 10px;
}

@media only screen and (max-width: 1280px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media only screen and (max-width: 700px) {
    .modal-dialog {
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-content {
        height: auto;
        max-height: 100%;
        border-radius: 0;
        border: none;
    }

    .modal-body {
        max-height: 100%;
        overflow-y: auto;
    }

    .modal-footer {
        bottom: 0px;
    }
}

.inputDescriptionDiv textarea {
  resize: none;
  width: 80%;
  float: left;
  display: block;
  height: 70px;
}
.inputName {
  font-size: 16px;
  width: 80%;
  float: left;
  display: block;
}
.inputPrice {
  font-size: 15px;
  width: 19%;
  float: right;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  text-align: right;
}
.inputInfoDiv,
.inputDescriptionDiv {
  display: block;
  float: right;
  width: 80%;
  margin-bottom: 6px;
  max-height: 48px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.productDiv {
  width: 48%;
  margin: 10px 1%;
  padding: 10px;
  display: inline-table;
  cursor: pointer;
  border: none;
  background-color: #fefefe;
  height: 120px;
  /* border: 0.5px solid transparent; */
}

.quantity {
  color: #fff;
}

.itemImgDiv {
  height: 80px;

  width: 14%;
  display: block;
  float: left;
}

.itemImgDiv img {
  max-width: 60px;
}

.productDiv:hover {
  /* outline: 0.5px solid #ccc; */
  /* border: 0.5px solid #ccc; */ /*mac te calisiyor*/
  /* border-color: #ccc; */
  box-shadow: 0 0 1.5px 0 #555;
  border-radius: 4px;
}

.productDiv .itemImage {
  float: left;
  width: 100%;
}

.itemInfoDiv,
.descriptionDiv {
  display: block;
  float: right;
  width: 84%;
  margin-bottom: 2px;
  max-height: 60px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.descriptionDiv {
  max-height: 50px;
  font-size: 12px;
  margin-bottom: 0px;
  /* white-space: nowrap;    
     img leri bozuyor, ... icin gerekli */
}

.quantityDiv {
  font-size: 14px;
}

.itemName {
  font-size: 16px;
  width: 50%;
  float: left;
  display: block;
}

.itemPrice {
  font-size: 15px;
  float: right;
  width: 20%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}

@media only screen and (max-width: 1100px) {
  .productDiv {
    max-height: 114px;
  }
  .itemImgDiv {
    width: 28%;

    height: 94px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 68%;
  }

  .itemName {
    max-width: 38%;
  }
}

@media only screen and (max-width: 750px) {
  .productDiv {
    width: 100%;
    margin: 10px 0px;
  }

  .itemImgDiv {
    width: 11%;

    height: 70px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .itemImgDiv {
    width: 17%;

    height: 70px;
  }

  .itemInfoDiv,
  .descriptionDiv {
    width: 80%;
  }
}

.kisaltmaClass {
  width: 100%;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* table.varolw {
    display: block;
    width: 100%;
}
table .varol2 {
    display: block;
}

table .varol2 th .divTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14%;
} */

.prodTableContainer {
  max-width: 100%;
  display: block;
}
.colsPriceCount {
  width: 50px;
  height: 25px;
}
.prodTableHead {
  text-align: center;
}

label {
  margin-bottom: 0px;
}

.storeInfoDiv .categoriesUl .active {
  border-bottom: none;
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .prodTableContainer {
    overflow-x: scroll;
  }
  .prodTableHead {
    font-size: 14px;
  }
}

.storeInfoDiv .storeImages {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  top: 0px;
  height: 240px;
}

.storeInfoDiv .logoImg {
  max-height: 102px;
  margin-left: 5%;
  margin-bottom: -10%;

  box-shadow: 0 0 10px 5px #ccc;
}

.storeInfoDiv .titleContainer {
  padding: 15px 25px;
}

.storeInfoDiv .titleDiv {
  display: table;
  width: 100%;
}

.storeInfoDiv .titleDiv > * {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.storeInfoDiv .titleDiv i {
  font-size: 22px;
  cursor: pointer;
}

.inputTitle {
  border: 1px dashed #bbb;
  color: green;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
}
.inputTitle:focus {
  outline: none !important;
  border: 1px dashed red;
}

@media only screen and (max-width: 1100px) {
  .storeInfoDiv .storeImages {
    height: 160px;
  }
  .storeInfoDiv .logoImg {
    max-height: 85px;

    margin-bottom: -5%;
  }
}
.thumbnail {
  /* min-height: 341px; */
  cursor: pointer;
  padding: 0;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  padding: 15px;
}
.thumbnail.editor .edit {
  border: 2px dashed #aaa;
  height: 100%;
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
  margin: auto;
}
.thumbnail.editor i {
  font-size: 60px;
  margin-bottom: 7px;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .storeInfoDiv {
    margin-bottom: 30px;
  }
  .storeInfoDiv .storeImages {
    height: 120px;
  }
  .storeInfoDiv .logoImg {
    max-height: 70px;
  }

  .storeInfoDiv .titleContainer {
    padding: 15px 5%;
  }
}

.cartDiv .btnCheckout {
    margin: 20px auto;
    display: block;
    text-align: center;
}
.cartDiv .cartInfo {
    border-bottom: 0.4px solid #ccc;
}

.cartDiv .cartTitle {
    margin-top: 60px;
}

.cartDiv .storeName {
    font-weight: 700;
}

.titleText {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #28a745;
}

.cartDiv .emptyCart {
    width: 100%;
    margin-top: 80px;
}
.cartDiv .fees {
    border-top: 0.4px solid #ccc;
    border-bottom: 0.4px solid #ccc;
}

/* .fees > td:last-child,
 tr:last-child > td:last-child, */
.cartDiv tr > td:last-child {
    text-align: right;
}

.cartDiv .emptyCart img {
    width: 65%;
    display: flex;
    margin: 10px auto;
}

.cartDiv .textEmpty {
    color: #777;
}

.cartDiv .cartInfoDiv {
    padding: 20px auto;
}
.cartDiv table {
    width: 94%;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .cartDiv table {
        width: 98%;
    }
}

@media only screen and (max-width: 700px) {
    .cartDiv table {
        width: 93%;
    }

    .cartDiv .cartTitle {
        margin-top: 30px;
    }
}

#warePopup {
    width: 26%;
}
#warePopup i {
    cursor: pointer;
}

.closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    cursor: pointer;
}

@media only screen and (max-width: 1150px) {
    #warePopup {
        width: 30%;
    }
}

@media only screen and (max-width: 991px) {
    #warePopup {
        width: 46%;
    }
}

@media only screen and (max-width: 767px) {
    #warePopup {
        width: 80%;
    }
}

#message {
    background-color: rgb(112, 52, 224);
    z-index: 4;
    height: 20px;
    width: auto;
    padding: 2px;
    position: absolute;
    bottom: 50px;
    right: 50px;
}
.pageDiv {
    width: 100%;
    background-color: #f1f1f1;
}

.pageDiv > div {
    display: inline-block;
}

.storeInfoDiv {
    width: 75%;
    min-height: 600px;
    background-color: #f8f8f8;
    border-right: 0.5px solid #aaa;
}

.cartDiv {
    width: 25%;
    height: auto;
    min-height: 300px;
    vertical-align: top;
    background-color: #eee;
}

@media only screen and (max-width: 1280px) {
    /* 13.3 inch mac icin 1280*/
    .cartDiv {
        width: 30%;
    }
    .storeInfoDiv {
        width: 70%;
    }
}

@media only screen and (max-width: 1100px) {
    .cartDiv {
        width: 34%;
    }
    .storeInfoDiv {
        width: 66%;
    }
}

@media only screen and (max-width: 900px) {
    .cartDiv,
    .storeInfoDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .cartDiv,
    .storeInfoDiv {
        width: 100%;
    }
}

.successDiv {
    position: fixed;
    bottom: 55px;
    right: 15px;
    line-height: 60px;
    background: #fff;
    font-size: 24px;
    color: green;
    padding: 10px;
    z-index: 10;
    border-radius: 2px;
}

.successDiv img {
    width: 60px;
    height: 60px;
    margin-right: 5px;
}

.successDiv {
    opacity: 1;
}

.successDiv.fade {
    opacity: 0;
    transition: opacity 0.5s;
}



.errorMsg {
    margin: 10px 0px;
    padding: 3px;
    color: #d8000c;
    background-color: #ffd2d2;
}



.varoll .multiImageContainer > * {
    width: 100%;
    display: block;
    margin: auto;
}

.col-12.col-sm-8.col-md-8.col-lg-8.col-xl-8.mb-1.row > * {
    margin-bottom: 5px;
}
.discountDivv > * {
    display: inline-block;
    width: 45%;
    margin-right: 4%;
}

.addressContainer {
  /* border: 1px solid #aaa; */
  border-radius: 5px;
  background-color: #eee;
  padding: 10px;
  display: inline-block;
  min-height: 80px;
  /* width: 48%;
    margin: 0px 1%; */
  vertical-align: top;
}
.addressContainer .addressInfo {
  background: #f8f8f8;
  position: relative;
  cursor: pointer;
  min-height: 150px;
  padding: 10px;
  border-radius: 5px;
}
.addressContainer .adressText label {
  display: block;
  margin-bottom: 4px;
}

table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}
*/ table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

/* .App {
  display: flex;
  flex-direction: column;
  padding: 20px;
} */
.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
/* input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
} */
.msku .msku-var .head,
.msku .msku-var .body {
  width: 100%;
  display: table;
}
.msku-var .left-section,
.msku-var .right-section,
.msku-var .place-holder {
  display: table-cell;
}
.msku-var > .body .left-section,
.msku-var > .body .right-section {
  padding: 15px;
  background: #fff;
}
.msku-var .left-section {
  width: 59%;
}
.msku-var .place-holder {
  width: 1.6%;
  padding: 0;
  background: transparent;
  border: 0;
  min-width: 15px;
}
.msku-var .left-section h4 {
  color: #777;
}
.msku-var .left-section .arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  position: absolute;
  right: -10px;
  top: 20px;
}
.msku-res .var-tag > span.readonly a,
.msku-res .var-dtl li.readonly a,
.msku-res .var-dtl i {
  display: none;
}
.var-tag span {
  color: #0654ba;
  padding: 10px 10px 10px 15px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fcfcfc;
  font-weight: 500;
  cursor: pointer;
  margin: 10px 15px 5px 0;
}
.var-tag .dn-imp {
  display: none !important;
}
.var-tag span > a,
.var-dtl a {
  background: url(https://ir.ebaystatic.com/rs/c/febear-src-pages-images-MSKUSprite-2qogWuPW.png)
    repeat scroll -3px -43px;
  display: inline-block;
  height: 11px;
  margin-left: 6px;
  width: 10px;
  font-weight: 500;
}
.var-tag span > a:hover {
  background-position: -3px -2px;
}
.var-tag .selected > a {
  background-position: -105px -2px;
}
.var-tag .selected > a:hover {
  background-position: -105px -43px;
}
.var-tag .selected {
  color: #fff;
  border-color: #aaa;
  background-color: #989898;
  position: relative;
}
.var-tag .selected::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.8em solid transparent;
  position: absolute;
  left: 50%;
  bottom: -20px;
  border-top: 10px solid #989898;
  margin-left: -10px;
}
.var-val {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.var-val .msku-variation-values h4 {
  font-weight: 500;
  color: #555;
  font-size: 14px;
  margin: 5px 0;
}
.var-val .var-tag span {
  margin: 5px 15px 10px 0;
}
.var-val ul {
  padding: 0;
  margin: 5px 0 0;
}
.var-val li {
  padding: 10px 15px;
  color: #0077d2;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px 10px 5px 0;
  display: inline-block;
  font-size: 12px;
}
.var-val li.selected {
  color: #000;
  background-color: #eee;
  font-weight: 500;
  box-shadow: 0 1px 3px #ddd inset;
}
.var-val li.selected:hover {
  border-color: #ddd;
}
.var-val li.readonly {
  cursor: default;
  box-shadow: none;
  background: #ddd;
}
.var-val li:hover {
  border-color: #bebebe;
}
.var-val a,
.var-tag a {
  color: #0654ba;
  font-weight: 500;
  text-decoration: none;
  outline: 0;
}
.var-tag .msg,
.var-dis span,
.var-dis span > a,
.var-dis > a {
  display: none;
}
.var-tag.var-dis .msg {
  display: block;
}
.var-dtl ul {
  padding-left: 0;
  margin: 0;
}
.var-dtl li span:hover {
  cursor: -webkit-grab;
  cursor: grab;
}
.var-dtl li a {
  background: url(https://ir.ebaystatic.com/rs/c/febear-src-pages-images-MSKUSprite-2qogWuPW.png)
    repeat scroll -4px -61px;
}
.var-dtl li a:hover {
  background-position: -4px -20px;
}
.var-dtl li {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  padding: 5px 8px;
  margin: 5px 10px 5px 0;
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
}
.msku-variation-details-tag-placeholder {
  padding: 10px 15px;
  color: #0077d2;
  background-color: #fff;
  border: 1px dashed #ddd;
  border-radius: 3px;
  margin: 5px 10px 5px 0;
  display: inline-block;
  font-size: 12px;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #f00;
  /* z-index: 1; */
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.itemDiscount {
  font-size: 15px;
  float: right;
  width: 100%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}
.errorDiscount {
  background-color: crimson;
  color: white;
}
.labelName {
  line-height: 16px;
  vertical-align: middle;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 20px;
  line-height: normal;
  font-family: Arial, Helvetica, sans-serif;
}
.inputName {
  /* border: 1px dashed #bbb; */
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
/* .inputName:focus {
  outline: none !important;
  border: 1px dashed rgb(33, 103, 233);
} */

.imgUplodingDiv {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: #f6f9fe;
  height: 10vh;
}
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.itemCountVar {
  font-size: 15px;
  float: right;
  width: 70%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}

.hoiseM {
  align-items: initial;
  display: flex;
  flex-direction: row;
  justify-content: intial;
  margin-bottom: 0;
  background: #00f;
  border: 0 !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 0rem 0rem !important;
}

#vertical-tabpanel-0,
#vertical-tabpanel-1,
#vertical-tabpanel-2,
#vertical-tabpanel-3,
#vertical-tabpanel-4,
#vertical-tabpanel-5 {
    width: calc(100% - 160px);
}

@media only screen and (max-width: 767px) {
    .MuiTabs-scroller.MuiTabs-scrollable {
        display: none;
    }
    .MuiTabs-root.MuiTabs-vertical {
        display: none;
    }
    #vertical-tabpanel-0,
    #vertical-tabpanel-1,
    #vertical-tabpanel-2,
    #vertical-tabpanel-3,
    #vertical-tabpanel-4,
    #vertical-tabpanel-5 {
        width: 100%;
        margin: 0;
        padding: 10px 20px;
    }
}

.cellIcons > * {
    cursor: pointer;
}

/* table.varolw {
    display: block;
    width: 100%;
}
table .varol2 {
    display: block;
}

table .varol2 th .divTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14%;
} */

.prodTableContainer {
  max-width: 100%;
  display: block;
}

.prodTableHead {
  text-align: center;
}

label {
  margin-bottom: 0px;
}

.storeInfoDiv .categoriesUl .active {
  border-bottom: none;
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .prodTableContainer {
    overflow-x: scroll;
  }
  .prodTableHead {
    font-size: 14px;
  }
}

.telmedPopup {
    position: relative;
}

.closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    cursor: pointer;
}

#telmedModal {
    width: 28%;
}

@media only screen and (max-width: 991px) {
    #telmedModal {
        width: 44%;
    }
}

@media only screen and (max-width: 767px) {
    #telmedModal {
        width: 82%;
    }
}

