.contentt {
    width: 100%;

    height: auto;
    min-height: 200px;
    background-color: #fcfcfc;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.bg-tele .navbar-toggler {
    float: left;
    padding: 0;
    margin-top: 3px;
}
