.cartIconDiv {
    padding: 2px 7px;
    border: 1px solid #333;
    border-radius: 10px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    background: #28a745;
}

.cartIconDiv i {
    margin-right: 8px;
}
