.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #bbb;
}

.logo {
    padding: 2px 0px;
    font-weight: 500;
    color: #444;
}

.navbar {
    border-bottom: 0.4px solid #aaa;
}

.dropdown-menu {
    background: #f2f2f2;
}
.dropdown-item {
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: middle;
}

.dropdown-item:hover {
    background: #ccc;
}
.dropdown-item img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}
.nav-link img {
    width: 30px;
    height: 20px;
    margin-right: 5px;
}

.languages {
    border: 0.5px solid #444;
    border-radius: 10px;
}

.productsUl {
    list-style: none;
    padding: 0px;
    color: #fff;
    margin: 0px;
    line-height: 38px;
}

/* ul li {
    display: inline;
    margin-right: 10px;
} */

.sortBySelect {
    border: 0.2px solid #aaa;
    padding: 2px 30px 2px 10px;
}
@media only screen and (max-width: 1100px) {
    h4 {
        font-size: 21px;
    }
}
@media only screen and (max-width: 600px) {
    .nav-link img {
        width: 40px;
        height: 27px;
        margin-right: 5px;
    }
}
