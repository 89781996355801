.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #bbb;
}

.logo {
    padding: 2px 0px;
    font-weight: 500;
    color: #444;
}

.bg-tava {
    background-color: #3e5062;
}

.logo span {
    font-size: 26px;
}

.navbar {
    border-bottom: 0.4px solid #aaa;
}

.dropdown-menu {
    background: #f2f2f2;
}
.dropdown-item {
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: middle;
}

.dropdown-item:hover {
    background: #ccc;
}
.dropdown-item img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
}
.nav-link img {
    width: 30px;
    height: 20px;
    margin-right: 5px;
}

.languages {
    border: 0.5px solid #444;
    border-radius: 10px;
}

/* ul li {
    display: inline;
    margin-right: 10px;
} */

.logo img {
    margin-left: 24px;
}
@media only screen and (max-width: 700px) {
    .languages,
    .cartIconDiv {
        max-width: 20%;
        padding-left: 10px;
    }

    .logo img {
        margin-left: 0px;
    }
    .bg-tava .navbar-toggler-icon i {
        color: #fff;
        font-size: 30px;
        margin-top: -1px;
    }
    .bg-tava .navbar-toggler:hover {
        outline: none !important;
        box-shadow: none;
    }
}
