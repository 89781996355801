.imgDrop {
    width: 100%;
    height: 100%;
    /* background: lightgreen; */
    opacity: 1;
    position: relative;
    padding: 0.5%;
}

.imgDrop:hover {
    opacity: 0.8;
}
.imgDrop .borderDash {
    width: 100%;
    height: 100%;
    border: 1px dashed red;
    display: table;
}
.allDropzones p {
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}

.allDropzones {
    position: relative;
    width: 100%;
    height: 300px;
    display: block;
    cursor: pointer;
}

.allDropzones .logoDrop {
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50px;
    bottom: 20px;
    border: 1px dashed red;
    display: table;
}

.allDropzones .logoDrop .logoDropImg {
    object-fit: cover;
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    display: table-cell;
}

.allDropzones .logoDrop .dropDiv {
    z-index: 3;
    display: table;
    vertical-align: middle;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
}
