.multiImageContainer {
  padding-top: 10px;
  width: 100%;
}
.multiImageContainer > * {
  width: 100%;
  display: block;
  margin: auto;
}
.multiImageContainer .firstTab .topText {
  padding-bottom: 8px;
  display: block;
  height: 30px;
}
.multiImageContainer .secondTab .topText {
  display: block;
  background-color: #999;
}
.multiImageContainer .colorText {
  color: #007bff;
  cursor: pointer;
}
ul {
  list-style: none;
  text-decoration: none;
}
.multiImageContainer .seperator {
  margin: 0px 10px;
}

.multiImageContainer .multiImageDiv {
  border: 1px solid #888;
  border-radius: 2px;
  min-height: 300px;
  position: relative;
}
.multiImageContainer .multiImageDiv > * {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.addImageBtn {
  border: 1px solid #aaa;
  color: #007bff;
}

.allImagesDiv ul {
  margin: 0px;
  padding: 0px;
  display: block;
}

.imageItemDrag {
  display: inline-block;
  border: 1px solid #888;
  /* width: 33.333%; */
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  color: #007bff;
  position: relative;
  cursor: move;
}

.imageItemDrag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.imageItemDrag .deleteItemDrag {
  width: 28px;
  height: 25px;
  position: absolute;
  right: -3px;
  top: -2px;
  z-index: 11;
  cursor: pointer;

  background: transparent;
  border: none;

  outline: none !important;
  box-shadow: none;
}

.imageItemDrag .deleteItemDrag img {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.addImageDiv {
  padding: 5px 2%;
}
.multiImageDiv .editImage {
  position: absolute;
  left: 0px;
  top: 5px;
  bottom: 5px;
  width: 50%;
  height: calc(100% - 10px);
}

.multiImageDiv .editImage img {
  width: 96%;
  margin: 0px auto 5px;
  height: calc(100% - 55px);
  object-fit: cover;
  display: block;
}

.clickElement {
  position: absolute;
  z-index: 10;
  left: 1px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  cursor: pointer;
  background: transparent;
  border: none;

  outline: none !important;
  box-shadow: none;
}

.multiImageDiv .editImage .editOptionsDiv {
  display: block;
  width: 100%;
  border-top: 1px solid #888;
  height: 50px;
  padding: 5px 10px;
  font-size: 22px;
  line-height: 40px;
  color: #888;
}

.multiImageDiv .editImage i {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.allImagesDiv ul li:nth-child(1) > * {
  /* display: inline-flex;
    height: 100%; */
}

.allImagesDiv ul li > * {
  width: 100%;
  height: 80px;
  display: block;
  margin-bottom: 0px;
}

.allImagesDiv ul li.addBtnTest:hover {
  color: blue;
  border: 1px dashed #444;
}

.allImagesDiv ul li label i {
  line-height: 45px;
  font-size: 25px;
}

.secondTab .addWebUrl {
  font-weight: 500;
  font-size: 18px;
}
.secondTab .webImageDiv {
  padding: 30px 50px;
  border: 1px solid #888;
  border-radius: 2px;
  min-height: 300px;
}
.webImageDiv .btn {
  width: auto;
}

.secondTab .addWebUrlDiv {
  margin: 10px 0px 80px 45px;
}

.secondTab .btn {
  display: inline-block;
}

.secondTab .webUrls .form-group label {
  width: 30px;
  text-align: center;
  margin: 0px 10px 15px 0px;
}

.secondTab .webUrls .form-group input {
  width: 350px;
}

.multiImageContainer .errorMessage {
  color: red;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  margin-top: 5px;
}

@media only screen and (max-width: 991px) {
  .multiImageContainer > * {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .multiImageContainer .multiImageDiv > * {
    width: 100%;
  }
  .multiImageContainer > * {
    width: 90%;
  }
  .multiImageContainer .firstTab .addImageDiv {
    padding: 30px 10px;
  }
}
.selectedImg {
  box-sizing: border-box;
  border: 5px solid red;
}
