#warePopup {
    width: 26%;
}
#warePopup i {
    cursor: pointer;
}

.closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    cursor: pointer;
}

@media only screen and (max-width: 1150px) {
    #warePopup {
        width: 30%;
    }
}

@media only screen and (max-width: 991px) {
    #warePopup {
        width: 46%;
    }
}

@media only screen and (max-width: 767px) {
    #warePopup {
        width: 80%;
    }
}
