table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}
*/ table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

/* .App {
  display: flex;
  flex-direction: column;
  padding: 20px;
} */
.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
/* input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
} */
.msku .msku-var .head,
.msku .msku-var .body {
  width: 100%;
  display: table;
}
.msku-var .left-section,
.msku-var .right-section,
.msku-var .place-holder {
  display: table-cell;
}
.msku-var > .body .left-section,
.msku-var > .body .right-section {
  padding: 15px;
  background: #fff;
}
.msku-var .left-section {
  width: 59%;
}
.msku-var .place-holder {
  width: 1.6%;
  padding: 0;
  background: transparent;
  border: 0;
  min-width: 15px;
}
.msku-var .left-section h4 {
  color: #777;
}
.msku-var .left-section .arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  position: absolute;
  right: -10px;
  top: 20px;
}
.msku-res .var-tag > span.readonly a,
.msku-res .var-dtl li.readonly a,
.msku-res .var-dtl i {
  display: none;
}
.var-tag span {
  color: #0654ba;
  padding: 10px 10px 10px 15px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fcfcfc;
  font-weight: 500;
  cursor: pointer;
  margin: 10px 15px 5px 0;
}
.var-tag .dn-imp {
  display: none !important;
}
.var-tag span > a,
.var-dtl a {
  background: url(https://ir.ebaystatic.com/rs/c/febear-src-pages-images-MSKUSprite-2qogWuPW.png)
    repeat scroll -3px -43px;
  display: inline-block;
  height: 11px;
  margin-left: 6px;
  width: 10px;
  font-weight: 500;
}
.var-tag span > a:hover {
  background-position: -3px -2px;
}
.var-tag .selected > a {
  background-position: -105px -2px;
}
.var-tag .selected > a:hover {
  background-position: -105px -43px;
}
.var-tag .selected {
  color: #fff;
  border-color: #aaa;
  background-color: #989898;
  position: relative;
}
.var-tag .selected::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.8em solid transparent;
  position: absolute;
  left: 50%;
  bottom: -20px;
  border-top: 10px solid #989898;
  margin-left: -10px;
}
.var-val {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.var-val .msku-variation-values h4 {
  font-weight: 500;
  color: #555;
  font-size: 14px;
  margin: 5px 0;
}
.var-val .var-tag span {
  margin: 5px 15px 10px 0;
}
.var-val ul {
  padding: 0;
  margin: 5px 0 0;
}
.var-val li {
  padding: 10px 15px;
  color: #0077d2;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px 10px 5px 0;
  display: inline-block;
  font-size: 12px;
}
.var-val li.selected {
  color: #000;
  background-color: #eee;
  font-weight: 500;
  box-shadow: 0 1px 3px #ddd inset;
}
.var-val li.selected:hover {
  border-color: #ddd;
}
.var-val li.readonly {
  cursor: default;
  box-shadow: none;
  background: #ddd;
}
.var-val li:hover {
  border-color: #bebebe;
}
.var-val a,
.var-tag a {
  color: #0654ba;
  font-weight: 500;
  text-decoration: none;
  outline: 0;
}
.var-tag .msg,
.var-dis span,
.var-dis span > a,
.var-dis > a {
  display: none;
}
.var-tag.var-dis .msg {
  display: block;
}
.var-dtl ul {
  padding-left: 0;
  margin: 0;
}
.var-dtl li span:hover {
  cursor: grab;
}
.var-dtl li a {
  background: url(https://ir.ebaystatic.com/rs/c/febear-src-pages-images-MSKUSprite-2qogWuPW.png)
    repeat scroll -4px -61px;
}
.var-dtl li a:hover {
  background-position: -4px -20px;
}
.var-dtl li {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  padding: 5px 8px;
  margin: 5px 10px 5px 0;
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
}
.msku-variation-details-tag-placeholder {
  padding: 10px 15px;
  color: #0077d2;
  background-color: #fff;
  border: 1px dashed #ddd;
  border-radius: 3px;
  margin: 5px 10px 5px 0;
  display: inline-block;
  font-size: 12px;
}
