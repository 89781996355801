.telmedPopup {
    position: relative;
}

.closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    cursor: pointer;
}

#telmedModal {
    width: 28%;
}

@media only screen and (max-width: 991px) {
    #telmedModal {
        width: 44%;
    }
}

@media only screen and (max-width: 767px) {
    #telmedModal {
        width: 82%;
    }
}
