#vertical-tabpanel-0,
#vertical-tabpanel-1,
#vertical-tabpanel-2,
#vertical-tabpanel-3,
#vertical-tabpanel-4,
#vertical-tabpanel-5 {
    width: calc(100% - 160px);
}

@media only screen and (max-width: 767px) {
    .MuiTabs-scroller.MuiTabs-scrollable {
        display: none;
    }
    .MuiTabs-root.MuiTabs-vertical {
        display: none;
    }
    #vertical-tabpanel-0,
    #vertical-tabpanel-1,
    #vertical-tabpanel-2,
    #vertical-tabpanel-3,
    #vertical-tabpanel-4,
    #vertical-tabpanel-5 {
        width: 100%;
        margin: 0;
        padding: 10px 20px;
    }
}
