.storeDiv {
    display: inline-block;
    padding: 5px;
    margin-bottom: 10px;

    width: 25%;
}

.storeDiv .content {
    min-height: 200px;
    border-radius: 10px;
    border: 1px solid #bbb;
}

.storeItemInfoDiv {
    padding: 10px 15px;
    cursor: pointer;

    font-size: 13px;
}

.storeItemInfoDiv > div {
    margin-bottom: 5px;
}

.storeName {
    font-weight: bold;
    font-size: 15px;
}

@media only screen and (max-width: 1100px) {
    .storeDiv {
        width: 33.33%;
    }
}

@media only screen and (max-width: 750px) {
    .storeDiv {
        min-width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .storeDiv {
        min-width: 100%;
    }
}
