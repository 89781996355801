.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #f00;
  /* z-index: 1; */
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}
