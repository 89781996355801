.btnFace {
    padding: 10px 0px;
    background-color: #3b5998;
    border: 1px solid #3b5998;
    border-radius: 3px;
    color: white;
    font-weight: 600;
}

.btnGuest {
    padding: 10px 0px;

    border: 1px solid #777;
    border-radius: 3px;
    font-weight: 600;
}

.divider span {
    display: block;
    width: 50px;
    background: #f4f4f4;
    text-align: center;
    color: #999;
    margin: -30px auto 0 auto;
    padding: 0 7px;
}
