.successDiv {
    position: fixed;
    bottom: 55px;
    right: 15px;
    line-height: 60px;
    background: #fff;
    font-size: 24px;
    color: green;
    padding: 10px;
    z-index: 10;
    border-radius: 2px;
}

.successDiv img {
    width: 60px;
    height: 60px;
    margin-right: 5px;
}

.successDiv {
    opacity: 1;
}

.successDiv.fade {
    opacity: 0;
    transition: opacity 0.5s;
}
