.storeContainer {
    padding: 10px 5%;
    margin-top: 5px;
    min-height: 200px;
    height: auto;
    width: 100%;
}

.help-message {
    text-align: center;
    font-size: 18px;
    padding: 15px;
    border: 1px solid #333;
    border-radius: 5px;
    width: 100%;
    display: block;
    background: #eaeaea;
}
