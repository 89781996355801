.cartDiv .btnCheckout {
    margin: 20px auto;
    display: block;
    text-align: center;
}
.cartDiv .cartInfo {
    border-bottom: 0.4px solid #ccc;
}

.cartDiv .cartTitle {
    margin-top: 60px;
}

.cartDiv .storeName {
    font-weight: 700;
}

.titleText {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #28a745;
}

.cartDiv .emptyCart {
    width: 100%;
    margin-top: 80px;
}
.cartDiv .fees {
    border-top: 0.4px solid #ccc;
    border-bottom: 0.4px solid #ccc;
}

/* .fees > td:last-child,
 tr:last-child > td:last-child, */
.cartDiv tr > td:last-child {
    text-align: right;
}

.cartDiv .emptyCart img {
    width: 65%;
    display: flex;
    margin: 10px auto;
}

.cartDiv .textEmpty {
    color: #777;
}

.cartDiv .cartInfoDiv {
    padding: 20px auto;
}
.cartDiv table {
    width: 94%;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .cartDiv table {
        width: 98%;
    }
}

@media only screen and (max-width: 700px) {
    .cartDiv table {
        width: 93%;
    }

    .cartDiv .cartTitle {
        margin-top: 30px;
    }
}
