.storeInfoDiv .pull-right {
    width: 35%;
}
.storeInfoDiv .categoriesUl {
    list-style: none;
    padding: 0px;
    color: #fff;
    margin: 0px;
    line-height: 38px;
}
.storeInfoDiv .categoryLi {
    cursor: pointer;
    color: blue;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
}

.storeInfoDiv .categoriesContainer {
    padding: 0px 3%;
    border-bottom: 0.6px solid #aaa;
    min-height: 60px;
    /* display: table;
     width: 100%; */
}

.storeInfoDiv .categoriesContainer > * {
    margin-bottom: 12px;
}

.storeInfoDiv .categoriesUl .active {
    /* text-decoration: underline; */ /*bunun disinda*/
    border-bottom: 2px solid blue;
}
.storeInfoDiv hr {
    display: none;
}
/* .storeInfoDiv  hr {
     display: block;
     border: none;
     border-bottom: 0.6px solid #aaa;
     margin: 0px -3%;
} */

@media only screen and (max-width: 1100px) {
    .storeInfoDiv .categoriesContainer {
        display: table;

        padding: 0px 5%;
        width: 100%;
    }
    .storeInfoDiv .pull-right {
        width: 100%;
    }

    .storeInfoDiv hr {
        display: block;
        border: none;
        border-bottom: 0.6px solid #aaa;
        margin: 0px -5%;
    }

    .storeInfoDiv .pull-left {
        min-width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .storeInfoDiv .categoriesUl > li {
        padding: 0px 4px;
    }
}
