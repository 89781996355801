.itemDiscount {
  font-size: 15px;
  float: right;
  width: 100%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}
.errorDiscount {
  background-color: crimson;
  color: white;
}
.labelName {
  line-height: 16px;
  vertical-align: middle;
  display: inline-block;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 20px;
  line-height: normal;
  font-family: Arial, Helvetica, sans-serif;
}
.inputName {
  /* border: 1px dashed #bbb; */
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
/* .inputName:focus {
  outline: none !important;
  border: 1px dashed rgb(33, 103, 233);
} */

.imgUplodingDiv {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: #f6f9fe;
  height: 10vh;
}
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.itemCountVar {
  font-size: 15px;
  float: right;
  width: 70%;
  color: teal;
  top: 0px;
  right: 0px;
  display: block;
  margin-left: 5px;
}

.hoiseM {
  -webkit-align-items: initial;
  -webkit-box-align: initial;
  -ms-flex-align: initial;
  align-items: initial;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: intial;
  -webkit-justify-content: intial;
  -ms-flex-pack: intial;
  justify-content: intial;
  margin-bottom: 0;
  background: #00f;
  border: 0 !important;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 0rem 0rem !important;
}
