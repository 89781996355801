.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #ccc;
  cursor: pointer;
}

.active {
  color: #ff6600;
}
