.imgPopup h3 {
    color: green;
}

.imgPopup {
    height: 100%;
    width: 100%;
}

.imgPopup i {
    display: none;
}

.imgPopup img {
    width: 100%;
    height: auto;
}

.modalBody {
    max-height: 485px;
}

@media only screen and (max-width: 700px) {
}
