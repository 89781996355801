.ratingDiv {
    display: table;
    margin-top: 10px;
}

.rating {
    display: inline-block;
    /* color: #888888; */
    height: 25px;
    width: auto;
    margin: 0;
    font-size: 25px;
    margin-right: 10px;

    position: relative;
    padding: 0;
}

.rating-upper {
    color: orange;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;

    overflow: hidden;
}

.rating-lower {
    padding: 0;
    display: flex;
    z-index: 0;
}

.starText {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
}

@media only screen and (max-width: 1100px) {
    .ratingDiv {
        display: inline-block;
    }
}

@media only screen and (max-width: 700px) {
    .ratingDiv {
        display: table;
    }
}
