.modal-footer > .changeQuantityDiv {
    /* margin: 5px 10px; */
    /* display: inline-block; */
    padding-top: 3px;
    padding-left: 0px;
}

.changeQuantityDiv > div {
    padding: 0px 12px 0px 6px;
    display: inline-block;
    font-size: 27px;
    font-weight: 700;
    cursor: pointer;
}

.orderTextarea {
    min-height: 80px;
    margin-top: 10px;
}

.plusDiv {
    color: #28a745;
}

button.close {
    font-size: 30px;
}

.addButton {
    padding: 8px 15px;
}

.modal-dialog {
    max-width: 50%;
    height: 500px;
}

.photoGallery {
    margin-bottom: 15px;
}

.modal-body {
    max-height: 400px;
    overflow-y: auto;
}
.modal-content {
    min-height: 100%;
    overflow-y: auto;
}

.addCartButton {
    min-width: 180px;
}

.quantText {
    float: left;
    width: 30px;
    background-color: #226622;
    display: block;
    text-align: center;

    border-radius: 5px;
}

.btnText {
    min-width: 80%;
    text-align: center;
}

.form-group {
    margin-bottom: 0px;
}
.inputBoxes {
    display: table;
    width: 100%;
    margin-bottom: 5px;
}

.inputBoxes > input {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    margin-right: 8px;
    margin-left: 10px;
}

.inputBoxes > label {
    display: table-cell;
    vertical-align: middle;
    margin-left: 8px;
    width: 100%;
}

.inputBoxes > label > .float-right {
    margin-right: 10px;
}

@media only screen and (max-width: 1280px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media only screen and (max-width: 700px) {
    .modal-dialog {
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-content {
        height: auto;
        max-height: 100%;
        border-radius: 0;
        border: none;
    }

    .modal-body {
        max-height: 100%;
        overflow-y: auto;
    }

    .modal-footer {
        bottom: 0px;
    }
}
