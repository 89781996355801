.varoll .multiImageContainer > * {
    width: 100%;
    display: block;
    margin: auto;
}

.col-12.col-sm-8.col-md-8.col-lg-8.col-xl-8.mb-1.row > * {
    margin-bottom: 5px;
}
.discountDivv > * {
    display: inline-block;
    width: 45%;
    margin-right: 4%;
}
