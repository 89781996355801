.signupDiv {
    margin: 20px auto;
    background-color: #f4f4f4;
    display: block;
    padding: 25px 22px;
    color: #333;
    border-radius: 10px;
    width: 30%;
}

.imgmango {
    display: flex;
    width: 220px;
    margin: 0px auto 20px;
}

.signupDiv form {
    margin-top: 20px;
}

form .btn {
    display: block;
    margin: 30px auto 10px;
    width: 50%;
}

form label {
    font-weight: 600;
}

@media only screen and (max-width: 1100px) {
    .signupDiv {
        width: 50%;
    }
}

@media only screen and (max-width: 700px) {
    .signupDiv {
        width: 90%;
    }
}
