.deleteDraft {
  cursor: pointer;
  color: #df0808;
}
.titleDiv1 {
  display: table;
}

.titleDiv1 input {
  width: calc(100% - 75px);
}

.titleDiv1 button {
  width: 75px;
}
